<template>
	<el-form ref="ruleForm" name="ruleForm" :inline="true" :model="formValue" class="saas-form" :size="formSize"
		@keyup.enter.native="handleClick('search')">
		<slot></slot>
		<template v-for="(item, index) in formItem">
			<div v-if="item.type === 'vertical'" :style="item.style">
				<el-divider class="title" direction="vertical"></el-divider>
				<span class="divider-title">{{ item.text }}</span>
			</div>
			<el-form-item v-if="computeDisplay(item.display) && !item.notFormItem && item.type !== 'button'" :key="index"
				:name="item.key + 'Item'" :label="item.label" :style="item.style" :prop="item.key" :ref="item.key"
				:class="`${item.type == 'textarea' ? 'saas-textarea' : ''}${item.className ? ' ' + item.className : ''}`"
				:rules="item.rules">
				<!-- 自定义label,这里是popover的展示方式 -->
				<template slot="label" v-if="item.labelDefine && item.labelDefine.popover">
					{{ item.labelDefine.label }}
					<el-popover placement="top" title="权限模式" :width="item.labelDefine.width ? item.labelDefine.width : 200"
						trigger="hover">
						<div v-html="item.labelDefine.render"></div>
						<i :class="item.labelDefine.icon" :style="item.labelDefine.style" slot="reference"></i>
					</el-popover>
				</template>
				<el-input v-if="item.type === 'input'" :id="item.key" v-model="formValue[item.key]" :name="item.key"
					:readonly="item.readonly" :disabled="item.disabled" :show-password="item.showPassword" :type="item.inputType"
					autocomplete="off" :maxlength="item.maxLength" :minlength="item.minLength" :max="item.max" :min="item.min"
					:placeholder="item.placeholder" show-word-limit title="" @input="(val) => {
						handleInput(val, item.handler)
					}" @focus="(val) => {
	handleFocus(val, item.handler)
}">
					<!-- <template slot="append">.com</template> -->
					<el-button v-if="item.appendIcon" slot="append" :size="formSize" :icon="item.appendIcon"
						@click.native="handleClick(item.handler)"></el-button>
				</el-input>
				<el-input-number v-if="item.type === 'number'" :id="item.key" v-model="formValue[item.key]"
					:placeholder="item.placeholder" :name="item.key" :disabled="item.disabled" controls-position="right"
					:min="item.min" :max="item.max"></el-input-number>
				<SAASTree4 v-if="item.type == 'saasTree4' && formConfig.treeData.length > 0" ref="saasTree4"
					:formValue='formValue' :treeData="formConfig.treeData" :treeProps="formConfig.treeProps"
					:formProps="formConfig.formProps || formConfig.treeProps" :notClick="formConfig.notClick"
					:disabled="formConfig.disabled" :isExpandAll="formConfig.isExpandAll" :expandedKeys="formConfig.expandedKeys"
					:placeholder="item.placeholder || '请选择组织'" @treeClickChange="(val) => { treeClickChange(val, item) }" />
				<!-- 解决上面一个页面中只能显示一个树 -->
				<SAASTree4 v-if="item.type == 'saasTree4V2' && formConfig.org.data.length > 0" ref="saasTree4V2"
					:formValue='formValue' :treeData="formConfig.org.data" :treeProps="formConfig.org.treeProps"
					:formProps="formConfig.org.formProps || formConfig.org.treeProps" :notClick="formConfig.org.notClick"
					:disabled="formConfig.org.disabled" :isExpandAll="formConfig.org.isExpandAll"
					:expandedKeys="formConfig.org.expandedKeys" :placeholder="item.placeholder || '请选择组织'"
					@treeClickChange="(val) => { treeClickChange(val, item) }" />
				<div v-if="item.type == 'orgTree'">
					<SAASOrgTree ref="saasOrgTree" :elName="elName" :formValue='formValue' :treeData="orgTreeList"
						:treeProps="formConfig.treeProps" :formProps="formConfig.formProps || formConfig.treeProps"
						:notClick="formConfig.notClick" :disabled="formConfig.disabled" :isExpandAll="formConfig.isExpandAll"
						:expandedKeys="formConfig.expandedKeys" :placeholder="item.placeholder || '请选择组织'" :pageType="item.pageType"
						@orgTreeClick="(val) => { orgTreeClick(val, item) }" />
				</div>
				<div v-if="item.type === 'vehicleTree'">
					<VehicleTree2 ref="vehicleTree" :disabled="item.disabled" :formValue='formValue'
						:treeProps="formConfig.treeProps" :notClick="formConfig.notClick" :checkBox="formConfig.checkBox"
						:treeData="vehicleTreeList" :dataType="item.dataType || null" :treeShowFlag="false"
						:placeholder="item.placeholder || '输入进行查询'" @vehicleTreeCheck="vehicleTreeCheck" />
				</div>
				<div v-if="item.type === 'alarmTree'">

					<SAASAlarmTree ref="alarmTree" :formValue='formValue' :formProps="formProps" :category='item.category'
						:placeholder="item.placeholder || '输入进行查询'" @handleClick="handleClick" @treeCheckChange="alarmTreeCheck" />
				</div>
				<div v-if="item.type === 'logTree'">
					<SAASLogTree ref="logTree" :formValue='formValue' :formProps="formProps" :category='item.category'
						:placeholder="item.placeholder || '输入进行查询'" @handleClick="handleClick" @treeCheckChange="logTreeCheck" />
				</div>
				<div v-if="item.type == 'uploadFile'">
					<el-input :id="item.key" v-model="formValue[item.key]" :name="item.key" :readonly="item.readonly"
						:disabled="item.disabled" :show-password="item.showPassword" :type="item.inputType" autocomplete="off"
						:maxlength="item.maxLength" :max="item.max" :min="item.min" :placeholder="item.placeholder" show-word-limit
						@input="(val) => {
							handleInput(val, item.handler)
						}"></el-input>
					<input v-if="!item.disabled" class="uploadFile" type="file" @change="uploadFile" />
				</div>
				<el-radio-group v-if="item.type === 'radio'" :id="item.key" v-model="formValue[item.key]" :name="item.key"
					:readonly="item.readonly" :disabled="item.disabled" @change="handleChange($event, item.handler)">
					<el-radio v-for="groupItem, index in item.group" :label="groupItem.label" :key="item.label + index">{{
						groupItem.name }}
					</el-radio>
				</el-radio-group>
				<el-select class="selectName" v-if="item.type === 'select'" :id="item.key" :ref="item.key"
					v-model="formValue[item.key]" :name="item.key" :class="item.key" :placeholder="item.placeholder || '请选择'"
					:disabled="item.disabled" :multiple="item.multiple" :collapse-tags="item.collapseTags" :readonly="item.readonly"
					:allow-create="item.allowCreate" :popper-class="item.popperClass" filterable :clearable="!item.clearable"
					@change="(val) => { handleChange(val, item.handler) }"
					@visible-change="(val) => { handleVisibleChange(val, item.handler) }">
					<el-option v-for="items in item.options" :key="items.value + ''" :label="items.label" :value="items.value">
						<span v-if="item.leftLabel" style="float: left" :style="items.style">{{ items.label }}</span>
						<span v-if="item.rightValue" style="float: right">{{ items.count }}</span>
					</el-option>
				</el-select>
				<el-cascader v-if="item.type === 'cascader'" :ref="item.key" :key="item.key" v-model="formValue[item.key]"
					:name="item.key" :options="item.options" :disabled="item.disabled" :popper-class="item.popperClass"
					:placeholder="item.placeholder ? item.placeholder : '请输入地区'" clearable :filterable="item.filterable || false"
					:props="{ expandTrigger: 'hover', checkStrictly: item.checkStrictly || false }"
					@change="((val) => { handleChange(val, item.handler, item.key) })"></el-cascader>
				<!-- noNeedTime可不传，不需要时间 -->
				<el-date-picker v-if="item.type === 'dateRange'" v-model="formValue[item.key]" :name="item.key"
					:type="item.noNeedTime ? 'daterange' : 'datetimerange'" :format='item.format' :value-format="item.format"
					range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" class="saas-date-picker"
					:clearable="item.clearable" :popper-class="item.popperClass" :picker-options="item.pickerOptions"
					:disabled="item.disabled" @change="(val) => { handleChange(val, item.handler) }"></el-date-picker>
				<el-date-picker v-if="item.type === 'datePicker'" v-model="formValue[item.key]" :name="item.key"
					:type="item.dateType" :placeholder="item.placeholder || '请选择时间'" range-separator="-" start-placeholder="开始时间"
					end-placeholder="结束时间" class="saas-date-picker" :format='item.format' :value-format="item.format"
					:popper-class="item.popperClass" :picker-options="item.pickerOptions" :disabled="item.disabled"
					:clearable="item.clearable ? true : false" @change="(val) => { handleChange(val, item.handler) }"></el-date-picker>
				<el-date-picker v-if="item.type === 'datePickerWeek'" v-model="formValue[item.key]" class="saas-date-picker"
					type="week" format="yyyy 第 WW 周" placeholder="选择周" :clearable="item.clearable ? true : false"
					:picker-options="item.pickerOptions" @change="(val) => { handleChange(val, item.handler) }">
				</el-date-picker>
				<el-time-picker v-if="item.type === 'time'" v-model="formValue[item.key]" :name="item.key" :type="item.dateType"
					class="saas-date-picker" :value-format="item.format" :format='item.format' :popper-class="item.popperClass"
					:picker-options="item.pickerOptions" :disabled="item.disabled"
					@change="(val) => { handleChange(val, item.handler) }"></el-time-picker>
				<el-input v-if="item.type === 'textarea'" v-model="formValue[item.key]" :name="item.key" type="textarea"
					:rows="item.rows || 2" :disabled="item.disabled" :readonly="item.readonly" :maxlength="item.maxLength"
					show-word-limit :placeholder="item.placeholder || '请输入内容'">
				</el-input>
				<el-checkbox-group v-if="item.type === 'checkbox'" v-model="formValue[item.key]" :name="item.key"
					:disabled="item.disabled" :readonly="item.readonly" @change="handleChange($event, item.handler)">
					<el-checkbox v-for="op in item.options" :key="op.value" :label="op.value">{{ op.label }}
					</el-checkbox>
				</el-checkbox-group>
				<span v-if="item.unit" class="unit"
					:style="{ position: item.unit ? 'absolute' : '', right: '0.2rem' }">{{ item.unit }}</span>
				<el-select v-if="item.type === 'searchinput'" filterable remote reserve-keyword clearable
					v-model="formValue[item.key]" :placeholder="item.placeholder" :remote-method="remoteMethod" :loading="loading">
					<el-option v-for="v in item.options" :key="v.value" :label="v.label" :value="v.value">
					</el-option>
				</el-select>
				<el-select v-if="item.type === 'searchinputv2'" filterable clearable v-model="formValue[item.key]"
					:placeholder="item.placeholder" :multiple="item.multiple != undefined ? item.multiple : false" collapse-tags remote
					:remote-method="remoteMethod" @clear="handleClear(item.handler)"
					@change="(val) => { handleChange(val, item.handler) }">
					<el-option v-for="v in item.options" :key="v.value" :label="v.label" :value="v.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-button v-if="item.type === 'button'" :name="item.key" :style="item.style" :prop="item.key" v-has="item.auth"
				:type="item.itemType" :disabled="item.disabled" class="saas-button" @click.native="handleClick(item.handler)">
				{{ item.text }}
			</el-button>
		</template>
	</el-form>
</template>

<script>
export default {
	name: "SAASForm",
	props: {
		formItem: {
			required: false
		},
		formProps: {
			required: false
		},
		formValue: {
			required: false
		},
		formConfig: {
			type: Object,
			default: function () {
				return {};
			}
		},
		vehicleTreeData: {
			required: false
		},
		orgTreeList: {
			required: false
		},
		elName: {
			required: false
		},
		moreIsVisible: {
			type: Boolean,
			default: false
		},
		formSize: {
			type: String,
			default: 'small'
		}
	},
	watch: {
		vehicleTreeData() {
			this.vehicleTreeList = this.vehicleTreeData;
		}
	},
	computed: {
		formItemButtons() {
			let arrObjFilter = this.formItem.filter(ele => ele.type == 'button');
			return arrObjFilter;
		},
		formItems() {
			let arrObjFilter = this.formItem.filter(ele => ele.type != 'button');
			return arrObjFilter;
		}
	},
	data() {
		return {
			dialogVisible: false, //tree
			inputListShow: false, // inputList
			inputListTimer: false, // inputList
			vehicleTreeList: this.vehicleTreeData,
			loading: false
		};
	},
	methods: {
		uploadFile(e) {
			let obj = e.target;
			if (!obj.files) {
				return;
			}
			var f = obj.files[0];
			this.$emit("handleClick", "uploadFile", f);
		},
		handleInput(val, handler) {
			this.$forceUpdate();
			this.$emit("handleInput", handler, val);
		},
		handleFocus(val, handler) {
			this.$emit("handleFocus", handler, val);
		},
		handleClick(handler) {
			this.$emit("handleClick", handler);
		},
		handleChange(value, handler, key) {
			this.$forceUpdate();
			if (key == "regions") {
				this.formValue.provinceCode = value[0];
				this.formValue.cityCode = value[1];
				this.formValue.countyCode = value[2] ? value[2] : "";
			} else if (value && key == "proCity") {
				this.formValue.provinceCode = value[0];
				this.formValue.cityCode = value[1];
			}
			if (handler) {
				this.$emit("handleChange", handler, value);
			}
		},
		numberChange(currentValue, oldValue, key, min, max) {
			if (!currentValue && min !== undefined) {
				this.formValue[key] = min;
			}
			if (!currentValue && min !== undefined) {
				this.formValue[key] = max;
			}
		},
		handleVisibleChange(value, handler) {
			if (handler) {
				this.$emit("handleVisibleChange", handler, value);
			}
		},
		treeClickChange(val, item) {
			this.$emit("handleClick", "saasTree4", val, item);
		},
		vehicleTreeCheck(handler, item) {
			this.$emit("handleClick", "vehicleTree", item);
		},
		orgTreeClick(val, item) {
			this.$emit("handleClick", "orgTree", val, item);
		},
		alarmTreeCheck(data) {
			this.$emit("handleClick", "alarmTree", data);
		},
		logTreeCheck(data, parentName) {
			this.$emit("handleClick", "logTree", data, parentName);
		},
		computeDisplay(options) {
			if (options == null) {
				return true;
			} else if (typeof options === "string") {
				return options !== "none";
			} else if (typeof options === "function") {
				return options(this.formValue);
			} else return false; //toDo
		},
		remoteMethod(query) {
			this.$emit('remoteMethod', query);
		},
		showloading(flag) {
			this.loading = flag;
		},
		handleClear(handler) {
			this.$emit("handleClear", handler);
		},


	}
};
</script>

<style lang="less">
.saas-form {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;

	.el-divider {
		background-color: #558FFF;
		width: 4px;
	}

	.el-input-number.is-controls-right .el-input-number__decrease,
	.el-input-number.is-controls-right .el-input-number__increase {
		line-height: 16px;
	}

	.el-input__icon,
	.el-range-separator {
		line-height: 26px !important;
	}


	.icon-xiala>input {
		background: transparent url('../../assets/images/common/xiala.png') no-repeat 100% center !important;
		background-size: 10% !important;
	}

	.el-input-group--prepend .el-input__inner,
	.el-input-group__append {
		background-color: rgba(19, 19, 63, 0.75);
		color: #fff;
		border-color: rgba(19, 19, 63, 0.75);
	}

	.el-select {
		.el-radio-button__inner {
			background: inherit;
			border-color: rgba(19, 19, 63, 0.75);
			color: rgba(255, 255, 255, 0.623);
		}
	}

	.uploadFile {
		position: absolute;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
	}

	.el-form-item {
		margin-right: 0;
		margin-bottom: 20px;
		position: relative;

		.el-form-item__label {
			width: 110px;
			float: left;
			line-height: 1.2;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			white-space: inherit;
		}

		&>div {
			width: calc(100% - 120px);
		}
	}

	.saas-button {
		font-size: 13px;
		padding: 0 15px;
		background: #306DEB;
		height: 34px;

		&:hover {
			background: #2064D1;
		}
	}

	.saas-date-picker {
		width: 100%;

		.el-range-input {
			width: 45%;
		}
	}

	.saas-textarea {
		height: 68px;

		.el-form-item__content {
			height: 68px;
			line-height: 34px;

			.el-textarea__inner {
				min-height: 0 !important;
				height: 100% !important;
			}
		}
	}

	.tree-contain {
		position: relative;

		.tree-body {
			position: absolute;
			z-index: 10;
			width: 100%;
			height: 320px;
			overflow: auto;

			.tree-component {
				padding: 0 5px;
			}
		}
	}
}
</style>
